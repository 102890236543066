<template>
  <div>
    <h2 class="mt-2">Assets</h2>
    <b-table striped hover :items="assets" :fields="fields" :sort-compare="tableSortCompare" :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc">
      <template #cell(name)="data">
        <div class="d-flex flex-grow">
          <editable :text="data.item.name" @change="(v) => updateObject('S2Videos', data.item, { name: v })">
            <font-awesome-icon icon="video" v-if="data.item.type == 'video'" />
            <font-awesome-icon icon="file-pdf" v-if="data.item.type == 'slides'" />
            <font-awesome-icon icon="globe-americas" v-if="data.item.type == 'embed'" />
            {{ data.item.name }}&nbsp;
          </editable>
          <div v-if="$debug.isOn" class="alert-info">
            {{ data.item.id }}
          </div>
          <b-button class="ml-auto" @click="previewAsset(data.item)">
            <font-awesome-icon icon="eye" />
          </b-button>
        </div>
      </template>
      <template #cell(created)="data">
        {{ data.item.created?.toDate() | formatDate }}
      </template>
      <template #cell(action)="data">
        <div class="form-inline sub-mr-2">
          <b-button @click="selectVideo(data.item)">Create Link</b-button>
          <b-button @click="deleteVideo(data.item)" v-if="false">
            <font-awesome-icon icon="trash" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-modal id="modal-link" ok-only ok-title="Close" ok-variant="secondary" centered title="Create Link">
      <b-form-group label="Visitor List">
        <b-input-group>
          <b-input v-model="link.visitor"></b-input>
          <b-input-group-append>
            <b-button @click="pasteToTextArea">Paste from Clipboard</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Unique Link" v-if="!link.visitor?.includes(';')">
        <b-input v-model="link.id"></b-input>
      </b-form-group>
      <b-form-group label="Link Prefix" v-else>
        <b-input v-model="link.id"></b-input>
      </b-form-group>
      <b-button variant="primary" @click="createLink(selectedVideo)">Create</b-button>
    </b-modal>
    <b-modal id="modal-preview" centered ok-only title="Preview" size="lg">
    <div v-if="selectedVideo">
      <slides-viewer v-if="selectedVideo.type == 'slides' && previewSlides" :slides="previewSlides" />
      <player ref="player" v-else-if="selectedVideo.type == 'video'" :config="{ autoplay: true }" :url="selectedVideo.url"/>
      <div v-else-if="selectedVideo.type == 'embed'" class="embed-responsive embed-responsive-16by9">
        <iframe ref="embed"
          :src="selectedVideo.url" frameborder="0" class="embed-responsive-item" allowfullscreen="true"
          mozallowfullscreen="true" webkitallowfullscreen="true"/>
      </div>
      <div v-else>
        <p>Unknown type</p>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('ListAssets');
import { db } from '@/services/db';
import { randomString } from '@/services/utils';
import { createLinkObject, getLinkUrl, getUniqueLink } from '@/services/links';
import { appConfig } from '@/services/appconfig';

export default {
  components: {
    Editable: () => import('@/components/editable.vue'),
    Player: () => import('@/views/tests/TestHLS.vue'),
    SlidesViewer: () => import('@/components/slidesViewer.vue'),
  },
  props: {
    assets: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      selectedVideo: null,

      sortBy: 'created',
      sortDesc: true,
      fields: [
        { key: 'Name', sortable: true },
        {
          key: 'created',
          label: "Date",
          sortable: true,
        },
        { key: 'Action', sortable: false },
      ],

      link: true,

      previewSlides: null,
    };
  },
  mounted() {
  },
  methods: {
    tableSortCompare(a, b, key) {
      if (key === 'created')
        return a[key].toDate() - b[key].toDate();
      else
        return false;
    },
    updateObject(colPath, obj, data) {
      db.collection(colPath).doc(obj.id).update(data);
    },
    previewAsset(asset) {
      this.selectedVideo = asset;
      if (asset.type == 'slides') {
        this.previewSlides = [];
        for (let i = 0; i < asset.numSlides; i++) {
          this.previewSlides.push(`${appConfig.contentServerHost}/s2/slides/${asset.id}/slide-${i}.png`);
        }
      }
      else {
        this.previewSlides = null;
      }
      this.$bvModal.show('modal-preview');
    },
    selectVideo(video) {
      this.selectedVideo = video;
      this.link = {
        visitor: "main",
        id: this.$store.state.settings?.linkPrefix + randomString(8),
      };
      this.$bvModal.show('modal-link');
    },
    deleteVideo(video) {
      log.log("deleteVideo", video);
      db.collection('S2Videos').doc(video.id).update({
        expired: true,
        deleted: true,
      });
    },
    pasteToTextArea() {
      navigator.clipboard.readText().then(text => {
        log.log("clipboard", text);
        // make a list from each line element
        let list = text.split(/\r\n|\n/);
        list = list.filter(line => line.length > 0);
        // if multiple lines, replace new line with a ";"
        this.link.visitor = list.join(";");
      });
    },
    createLink(video) {
      let visitorList = this.link.visitor.split(";");
      if (visitorList.length == 1) {
        let link = this.createLinkObject(video, this.link.id, false, this.link.visitor);
        let url = getLinkUrl(link.id);
        navigator.clipboard.writeText(url);
        this.$bvToast.toast(url, {
          title: "Link created and copied to Clipboard",
          variant: "success",
          solid: true
        })
      }
      else {
        visitorList.forEach(visitor => {
          this.createLinkObject(video, this.link.id, true, visitor);
        });
        this.$bvToast.toast("Done", {
          title: `${visitorList.length} links created, see Links Tab`,
          variant: "success",
          solid: true
        })
      }
      this.$bvModal.hide('modal-link');
    },
    createLinkObject(video, linkPrefix, linkSuffix, visitor) {
      log.log("createLinkObject", video);
      let linkId = getUniqueLink(linkPrefix, linkSuffix);
      return createLinkObject(video, linkId, visitor);
    }
  }
}
</script>
